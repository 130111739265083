<template>
  <div class="card payments-custom-wrapper">
    <div class="card-header d-flex justify-content-between">
      <div><i class="fa fa-align-justify"></i> Liste des commandes</div>

      <div class="d-flex">
        <div v-tooltip="tooltipRequiredField" class="float-right">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="newOrder()"
            v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
            :disabled="!checkRequiredFields()"
          >
            <i class="fa fa-plus"></i>&nbsp; Ajouter
          </button>
        </div>

        <button
          type="button"
          class="btn btn-sm btn-warning ml-1"
          @click="openMarketModal()"
          v-if="
            hasPermission($store.state.user, 'ORDERS_WRITE') && isCollectivity
          "
          :disabled="!checkRequiredFields()"
        >
          <i class="fa fa-plus"></i>&nbsp; Ajouter commande marché
        </button>

        <div
          v-if="client.prospect && client.prospect.isProspect"
          v-tooltip="tooltipRequiredField"
          class="float-right"
        >
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="newDiscoveryOrder()"
            v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
            :disabled="!checkRequiredFields()"
          >
            <i class="fa fa-envelope"></i>&nbsp; Séance découverte
          </button>
        </div>
      </div>
    </div>

    <div class="card-block">
      <div class="w-100 mw-100 overflow-auto">
        <Orders
          :orders="orders"
          :client="false"
          :notes="true"
          :state="true"
          :showFrequency="true"
          :showInvoiceFile="true"
          :showIntervenants="true"
          @showNotes="displayOrderNotes"
          @notebookNoteAdded="notebookNoteAdded"
          @notebookNoteUpdated="notebookNoteUpdated"
          @notebookNoteDeleted="notebookNoteDeleted"
        />
      </div>
    </div>

    <modal
      title="Création de commande bloquée"
      v-model="unpaidCommandModal"
      effect="fade/zoom"
    >
      <div slot="modal-body" class="modal-body">
        <p>
          Ce client cumule trop d’impayés, tu ne peux pas effectuer cette
          action.
          <br />
          Contacte ton responsable afin de l’en informer.
        </p>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="unpaidCommandModal = false"
        >
          Fermer
        </button>
      </div>
    </modal>

    <modal
      title="Création de commande découverte"
      v-model="newDiscoveryOrderModal"
      large
      effect="fade/zoom"
    >
      <div slot="modal-body" class="modal-body">
        <div>
          <p>Type de la commande découverte ?</p>
          <el-switch
            v-model="discoveryType"
            inactive-text="Visio"
            active-text="Company"
            size="mini"
            @change="changeDiscoveryType($event)"
            :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
          />
        </div>
        <div
          v-if="
            discoveryType === true &&
            client.urssafInscription &&
            client.urssafInscription.valid
          "
          class="mt-3"
        >
          <p>
            La commande découverte contient-elle un paiement "Avance immédiate"
            ?
          </p>
          <el-switch
            v-model="isDiscoverAi"
            inactive-text="Stripe"
            active-text="Avance immédiate"
            size="mini"
            :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
          />
        </div>
      </div>
      <div
        slot="modal-footer"
        class="modal-footer d-flex justify-content-between"
      >
        <div class="alert alert-warning">
          ℹ️ Attention, cette action envoie un email avec un lien de paiement au
          client.
        </div>
        <div>
          <button
            type="button"
            class="btn btn-default"
            @click="newDiscoveryOrderModal = false"
          >
            Fermer
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="createNewDiscoveryOrder()"
          >
            Envoyer
          </button>
        </div>
      </div>
    </modal>

    <OrderModal
      :modalShow="openModal"
      :client="client"
      :requiredSessionUserCount="requiredSessionUserCount"
      @orderLoading="$emit('orderLoading', $event)"
      @close="reloadOrders"
      @closeModal="openModal = false"
    />

    <ModalMarketEstablishmentOrder
      :isShow="isOpenMarketModal"
      @close="closeMarketModal"
      @closeModal="isOpenMarketModal = false"
      :client="client"
    />

    <modal
      v-if="modalUpdateOrderManagerNotes.order"
      title="Label de commande"
      v-model="modalUpdateOrderManagerNotes.display"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateOrderNotes()"
      effect="fade/zoom"
    >
      <i> Visible par le coach</i>
      <textarea
        v-model="modalUpdateOrderManagerNotes.order.label"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";

import Orders from "../components/Orders";
import OrderModal from "../components/OrderModal";
import ModalMarketEstablishmentOrder from "./ModalMarketEstablishmentOrder.vue";

export default {
  components: {
    Orders,
    Modal,
    OrderModal,
    ModalMarketEstablishmentOrder,
  },

  props: {
    clientData: {
      type: Object,
      required: true,
    },

    notebook: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      orders: [],
      unpaidOrdersQuantity: 0,
      unpaidCommandModal: false,
      openModal: false,
      newDiscoveryOrderModal: false,
      isDiscoverAi: false,
      discoveryType: true,
      createOrderLoader: false,
      modalUpdateOrderManagerNotes: {
        display: false,
        order: undefined,
      },
      isOpenMarketModal: false,
      client: null,
    };
  },

  created() {
    this.client = this.clientData;
  },

  computed: {
    isCollectivity() {
      return this.client.isB2B && this.client.marketEstablishment;
    },

    tooltipRequiredField() {
      return `Les champs Prénom / Nom / Sexe / Téléphone / Date de naissance /
      Adresse / Lieux des séances et Personnes / séances / Disponibilités /
      Objectifs et Details : sont obligatoires pour ajouter une commande.`;
    },

    newOrderBlocked() {
      return (
        this.unpaidOrdersQuantity &&
        this.clientHasConceptAffectedByOrderBlocking &&
        !this.hasPermission(
          this.$store.state.user,
          "BLOCKED_IMPAYED_ORDERS_READ"
        )
      );
    },

    clientHasConceptAffectedByOrderBlocking() {
      const conceptsNames = [
        "justcoaching.fr",
        "Body Trainers",
        "luxurycoaching.fr",
      ];

      return this.client.concepts?.some((concept) =>
        conceptsNames.includes(concept.name)
      );
    },

    requiredSessionUserCount() {
      return this.client.groups?.some((group) =>
        group.features?.includes("SESSION_USER_COUNT")
      );
    },
  },

  watch: {
    notebook(value) {
      this.orders = this.orders.map((o) => ({
        ...o,
        notebook: value?.filter((n) => n.orderId === o.id) || [],
      }));
    },

    clientData: {
      handler(value) {
        this.client = value;
      },
      deep: true,
    },
  },

  mounted() {
    this.getUnpaidOrdersQuantity();

    this.orders = this.client.orders.map((o) => ({
      ...o,
      refClient: this.client,
      notebook: this.notebook?.filter((n) => n.orderId === o.id) || [],
    }));
  },

  methods: {
    async getUnpaidOrdersQuantity() {
      try {
        const { data } = await this.$api.get(
          "/clients/orders/unpaid-quantity",
          {
            params: {
              client: this.client.email,
            },
          }
        );

        this.unpaidOrdersQuantity = data.unpaidQuantity;
      } catch (e) {
        this.unpaidOrdersQuantity = 0;
      }
    },

    checkRequiredFields() {
      if (this.isAdmin(this.$store.state.user)) {
        return true;
      }

      return (
        this.client.firstName &&
        this.client.lastName &&
        this.client.gender &&
        this.client.phone &&
        this.client.birthDate &&
        this.client.preferedPlace &&
        this.client.zipcode &&
        this.client.disponibility &&
        this.client.objectif &&
        this.client.clientNotes
      );
    },

    newOrder() {
      if (this.newOrderBlocked) {
        this.unpaidCommandModal = true;

        return;
      }

      this.openModal = true;
    },

    newDiscoveryOrder() {
      this.newDiscoveryOrderModal = true;
    },

    async createNewDiscoveryOrder() {
      if (this.createOrderLoader) {
        return;
      }

      this.createOrderLoader = true;

      const { data } = await this.$api.post("orders/discovery", {
        clientEmail: this.client.email,
        isAi: this.isDiscoverAi,
        type: this.discoveryType ? "COMP" : "VISIO",
      });

      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: data.id,
        },
      });

      this.createOrderLoader = true;
      window.open(route.href, "_blank");
      window.location.reload();
    },

    reloadOrders() {
      this.openModal = false;
      this.$emit("newOrder");
    },

    changeDiscoveryType(value) {
      if (value === false) {
        this.isDiscoverAi = false;
      }
    },

    displayOrderNotes(order) {
      this.modalUpdateOrderManagerNotes.display = true;
      this.modalUpdateOrderManagerNotes.order = order;
    },

    async updateOrderNotes() {
      const { id, label } = this.modalUpdateOrderManagerNotes.order;

      try {
        await this.$api.put(`/orders/${id}`, {
          id,
          label,
          clientEmail: this.client.email,
        });

        this.$successToast("Le label de commande a bien été modifié");
        this.modalUpdateOrderManagerNotes.display = false;
      } catch (e) {
        this.$errorToast("La mise à jour du label de commande a échoué");
      }
    },

    notebookNoteAdded({ orderId, note }) {
      const order = this.orders.find((o) => o.id === orderId);

      if (order) {
        this.orders = this.orders.map((o) =>
          o.id === orderId ? { ...o, notebook: [note, ...o.notebook] } : o
        );
      }

      this.$emit("notebookNoteAdded", { orderId, note });
    },

    notebookNoteUpdated({ orderId, note }) {
      const order = this.orders.find((o) => o.id === orderId);

      if (order) {
        this.orders = this.orders.map((o) =>
          o.id === orderId
            ? {
                ...o,
                notebook: o.notebook.map((n) => (n.id === note.id ? note : n)),
              }
            : o
        );
      }

      this.$emit("notebookNoteUpdated", { orderId, note });
    },

    notebookNoteDeleted({ orderId, noteId }) {
      const order = this.orders.find((o) => o.id === orderId);

      if (order) {
        this.orders = this.orders.map((o) =>
          o.id === orderId
            ? {
                ...o,
                notebook: o.notebook.filter((n) => n.id !== noteId),
              }
            : o
        );
      }

      this.$emit("notebookNoteDeleted", { orderId, noteId });
    },

    openMarketModal() {
      this.isOpenMarketModal = true;
    },

    closeMarketModal() {
      this.isOpenMarketModal = false;
      this.$emit("newOrder");
    },
  },
};
</script>
